@tailwind base;
@tailwind components;
@tailwind utilities;

.article-body ol {
  counter-reset: item;
}

/* Use a counter that checks the number of items and adds a "." between them and ends with ". " */
.article-body ol>li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  font-style: italic;
  display: inline-block;
  padding: 0.1em 0.25em;
  margin-right: 0.5em;
  background-color: #EEE;
  border-radius: 0.1em;
}

.error {
  @apply mt-4;
  @apply p-4;
  @apply rounded-md;;
  @apply border-crimson;
  @apply border;
  @apply border-l-8;
  @apply bg-crimson-pale;
}

.success {
  @apply mt-4;
  @apply p-4;
  @apply rounded-md;;
  @apply border-green-apple;
  @apply border;
  @apply border-l-8;
  @apply bg-green-apple-pale;
}